import React from "react";
import CustomizedAccordions from "./Accordion";

const About = () => {
  return (
    <div>
      {/* About Section */}
      <section className="AboutImg"></section>
      
      <div className="container AboutContent">
        <div className="row">
          <div className="col-12 mx-auto">
            <p>
              <strong>Brightfare Travels Ltd.</strong> is a well-established
              online travel agent providing services for all your travel needs, 
              from flights to hotels, car hire to travel insurance, and much more. 
              Our experienced specialist consultants are available to answer all your 
              travel queries, ensuring confidence and after-sale support wherever 
              you go in the world.
            </p>

            <p>
              Our multilingual team is ready to assist you 24/7 with all kinds of 
              travel requirements.
            </p>

            <p>
              <strong>Brightfare Travels Ltd.</strong> stands out among travel agents 
              due to its expertise, range, and value. Whether you need to book flights 
              or holiday packages, our experienced team and 24x7 customer support 
              representatives ensure a seamless experience. With strong partnerships 
              with leading travel suppliers, we provide discounted "negotiated" fares 
              to clients. We specialize in breathtaking destinations worldwide, with 
              a focus on long and mid-haul destinations such as Asia, the Far East, 
              and Australia.
            </p>

            <p>
              Travel products can be accessed via telephone, email, or live chat inquiries. 
              We use leading systems like Galileo, Worldspan, and Sabre GDSs for airfares 
              distribution and management. Our close relationships with travel suppliers 
              allow us to offer discounts and secure seats during peak travel dates or 
              last-minute bookings.
            </p>

            <h4><strong>How do we book the flights?</strong></h4>
            <p>
              After reservation, we confirm details via an invoice within 2 hours of 
              client confirmation. Tickets are issued electronically within 48 hours 
              after full payment and sent via email at least 7 days before departure. 
              For travels within 7 days, tickets are sent within 24 hours of payment.
            </p>

            <h4><strong>Electronic Ticket</strong></h4>
            <p>
              An e-ticket is an electronic record of a passenger's ticket stored in the 
              airline's system. Passengers simply need to print their receipt/itinerary 
              with the booking reference and present it at the check-in desk along with 
              the required information.
            </p>

            <h2>FAQ's</h2>
            <CustomizedAccordions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
