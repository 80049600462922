import React, { useState } from "react";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();

  const initialState = {
    Full_name: "",
    mail: "",
    phoneNum: "",
    headtitle: "",
    line: "",
  };
  const [contactData, setContactData] = useState(initialState);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const contactEmail = (e) => {
    e.preventDefault();
    
    emailjs
      // .sendForm(
      //   "service_kek5ska",
      //   "template_4lh3b0u",
      //   e.target,
      //   "3Ftc-ODHIbWAThyqn"
      // )
      .sendForm(
        "service_kek5ska",
        "template_4lh3b0u",
        e.target,
        "3Ftc-ODHIbWAThyqn"
      )
      .then((res) => {
        console.log(res);
        // Reset the form fields
        setContactData(initialState);
      })
      .catch((err) => console.log("error", err));
    navigate("/Confirmation");
  };

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <section>
        <div className="Contactimg">
          <h1 className="contact pt-5 pt-lg-0 order-2 order-lg-1 text-center">
            {/* Contact Us */}
          </h1>
        </div>
        <br />
        <div className="container contact_content">
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <h1>CONTACT INFO</h1>
                <br />
                <Link to="#">admin@brightfare.co.uk</Link>
                <br />
                <br />
                <br />
                <h4>PHONE</h4>
                <br />
                <p>
                  <span className="ml-1">
                    <LocalPhoneRoundedIcon />
                  </span>
                  0203 8313 492
                </p>
                <p>
                  <span className="ml-1">
                    <WhatsAppIcon />
                  </span>
                  0203 8313 492
                </p>
                <br />
                {/* <p>
                  <strong>Reg No:</strong> 13267616
                </p> */}
              </div>
              <br />
              <br />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h1>CONTACT FORM</h1>
              <br />
              <form onSubmit={contactEmail} method="POST">
                <div className="form-group">
                  <input
                    required
                    type="text"
                    autoComplete="off"
                    name="Full_name"
                    placeholder="Full Name"
                    value={contactData.Full_name}
                    onChange={inputEvent}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    type="text"
                    name="mail"
                    autoComplete="off"
                    placeholder="Email"
                    value={contactData.mail}
                    onChange={inputEvent}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    type="tel"
                    maxLength={11}
                    name="phoneNum"
                    autoComplete="off"
                    placeholder="Phone Number"
                    value={contactData.phoneNum}
                    onChange={inputEvent}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    type="text"
                    autoComplete="off"
                    name="headtitle"
                    placeholder="Message Title"
                    value={contactData.headtitle}
                    onChange={inputEvent}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    required
                    style={{ resize: "none" }}
                    type="text"
                    name="line"
                    autoComplete="off"
                    value={contactData.line}
                    onChange={inputEvent}
                    placeholder="Message Paragraph"
                    className="form-control"
                    rows={7}
                  />
                </div>
                <div className="form-group">
                  <button
                    onClick={handleLinkClick}
                    type="submit"
                    className="btn btn-success"
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    </>
  );
};
export default Contact;