import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/Black_Logo.png";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Helmet } from "react-helmet";

const Navbar = () => {
  // Dynamic Title Update
  const setHelmetTitle = (pageTitle) => {
    document.title = `Brightfare Ltd | ${pageTitle}`;
  };

  // State for sidebar
  const [sidebarState, setSidebarState] = useState(false);

  // Toggle Sidebar
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setSidebarState(open);
  };

  // Sidebar Content
  const sidebarLinks = [
    { to: "/", label: "Home", pageTitle: "Home" },
    { to: "/fly", label: "Flights", pageTitle: "Flights" },
    { to: "/hajj", label: "Hajj & Ummrah", pageTitle: "Hajj & Ummrah" },
    { to: "/about", label: "About Us", pageTitle: "About Us" },
    { to: "/contact", label: "Contact Us", pageTitle: "Contact Us" },
  ];

  const renderSidebarList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="sidebarContainer"
    >
      <div className="sidebarlogo">
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src={logo} alt="Bright_logo" />
        </Link>
      </div>
      <List>
        {sidebarLinks.map(({ to, label, pageTitle }) => (
          <ListItem key={to} disablePadding>
            <ListItemButton>
              <Link
                to={to}
                className="sidebarnav"
                onClick={() => {
                  setHelmetTitle(pageTitle);
                  window.scrollTo(0, 0);
                }}
              >
                <ListItemText primary={label} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  // Scroll Detection
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.pageYOffset > 500);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <title>Brightfare Ltd</title>
      </Helmet>

      {/* Topbar for Small Screens */}
      <section className="topbar d-sm-none">
        <div className="callingmbltxt">
          <h5>
            <a href="tel:02038313492">0203 8313 492</a>
          </h5>
          <p>Open 24/7, so you don't have to chase the clock</p>
        </div>
      </section>

      {/* Navbar Section */}
      <section className={`header ${isScrolled ? "fixed-top" : ""}`}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              {/* Mobile Sidebar Toggle */}
              <div className="d-lg-none">
                <MenuOpenIcon
                  color="inherit"
                  onClick={toggleDrawer(true)}
                  style={{ color: "white", cursor: "pointer", marginRight: 30 }}
                />
                <Drawer anchor="right" open={sidebarState} onClose={toggleDrawer(false)}>
                  {renderSidebarList()}
                </Drawer>
              </div>

              {/* Navbar Links for Desktop */}
              <div className="nav_list collapse navbar-collapse d-sm-none">
                <ul className="navbar-nav">
                  {sidebarLinks.map(({ to, label, pageTitle }) => (
                    <li className="nav-item" key={to}>
                      <Link
                        to={to}
                        className="nav-link"
                        onClick={() => {
                          setHelmetTitle(pageTitle);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Contact Information */}
              <div className="callingtxt">
                <h4>0203 8313 492</h4>
                <p>
                  Open 24/7
                  <br />
                  so you don't have to chase the clock
                </p>
              </div>
            </div>
          </nav>
        </div>
      </section>

      {/* Trustpilot Section */}
      <div>
        <a
          className="Trust"
          target="_blank"
          rel="noreferrer"
          href="https://www.trustpilot.com/review/brightfare.co.uk"
        >
          {[...Array(5)].map((_, i) => (
            <StarIcon key={i} style={{ color: "#03DA8D" }} />
          ))}
          <h5>Trustpilot</h5>
        </a>
      </div>
    </>
  );
};

export default Navbar;
